import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BullhornIcon } from '@zydalabs/zac-icons-react';
import { useLocation } from '@reach/router';

import { Field, Text } from 'components/service';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { context as userContext } from 'context/user';
import { context as featureAccessContext } from 'context/feature-blocking';
import { ReactComponent as RetentionCampaignIcon } from 'assets/retention-campaign-refresh.svg';
import { ReactComponent as SegmentTargetingCampaignIcon } from 'assets/segment-targeting-users.svg';
import {
  RETENTION,
  RETENTION_DESCRIPTION,
  SEGMENT_TARGETING,
  SEGMENT_TARGETING_DESCRIPTION,
  CAMPAIGN_TITLE,
  EX_CHECK_OUR_OFFERS,
  ANNOUNCEMENT,
  ANNOUNCEMENT_DESCRIPTION,
} from 'constants/translations';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';
import CampaignTypeRadioGroup from './CampaignTypeRadioGroup';
import { CAMPAIGN_TYPES, CAMPAIGN_OBJECT_KEYS } from './constants';

function CampaignTypeStep({
  handleChange,
  lang,
  translate,
  isEdit = false,
  resetValuesOnTypeChange,
  remainingCampaign,
}) {
  const { featureAccess } = useContext(featureAccessContext);
  const { selectedStore } = useContext(userContext);
  const location = useLocation();

  return (
    <div className="pt-6 md:pt-10">
      <div className={cx('w-full mb-2 lg:w-5/12', lang === 'en' ? 'md:pr-3' : 'md:pl-3')}>
        <Label title={<Text value={CAMPAIGN_TITLE} />}>
          <Field
            type="text"
            name={CAMPAIGN_OBJECT_KEYS.TITLE}
            disabled={isEdit}
            maxLength={50}
            placeholder={translate(EX_CHECK_OUR_OFFERS)}
            component={Input}
          />
        </Label>
      </div>
      <Field
        name={CAMPAIGN_OBJECT_KEYS.TYPE}
        items={[
          {
            title: RETENTION,
            value: CAMPAIGN_TYPES.RETENTION,
            description: RETENTION_DESCRIPTION,
            icon: fill => <RetentionCampaignIcon fill={fill} />,
            disable: false,
          },
          {
            title: SEGMENT_TARGETING,
            value: CAMPAIGN_TYPES.SEGMENT_TARGETING,
            description: SEGMENT_TARGETING_DESCRIPTION,
            icon: fill => <SegmentTargetingCampaignIcon fill={fill} />,
            upgradeable: featureAccess
              .get(ACCESS_MAP_KEYS.FEATURES)
              ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id))?.SEGMENT_TARGETING?.isBlocked,
            disable: false,
          },
          {
            title: ANNOUNCEMENT,
            value: CAMPAIGN_TYPES.ANNOUNCEMENT,
            description: ANNOUNCEMENT_DESCRIPTION,
            icon: fill => <BullhornIcon width="24" color={fill} />,
            upgradeable: featureAccess
              .get(ACCESS_MAP_KEYS.FEATURES)
              ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id))?.SEGMENT_TARGETING?.isBlocked,
            disable: remainingCampaign && remainingCampaign === 0,
          },
        ]}
        component={CampaignTypeRadioGroup}
        onChange={e => {
          handleChange(e);
          resetValuesOnTypeChange();
        }}
        remainingCampaign={remainingCampaign}
      />
    </div>
  );
}

CampaignTypeStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  resetValuesOnTypeChange: PropTypes.func.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']),
  remainingCampaign: PropTypes.number,
};

export default CampaignTypeStep;
