import React, { useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { useQuery } from '@apollo/client';

import { Text, Link } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Spinner } from 'components/kit';
import { useMobile, useSelectedStore } from 'hooks/index';
import { BranchAddingModal } from 'components/common/branch';
import { context as notificationsContext } from 'context/notifications';
import { BUSINESS_LOCATION_LINKS } from 'constants/helperLinks';
import { PLAN_TYPES } from 'constants/featureAccessMatrix';
import Table from './Table';
import * as schemas from './schemas';
import EmptyState from './EmptyState';
import { SUBSCRIPTION_PLAN } from './schemas';

export default () => {
  const selectedStoreId = useSelectedStore();
  const { user, selectedStore } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);

  const isMobile = useMobile();

  const subscription = useQuery(SUBSCRIPTION_PLAN, {
    fetchPolicy: 'cache-and-network',
    variables: {
      storeId: selectedStoreId,
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ extensions }) => {
          const { body } = extensions?.exception || {};
          if (body?.error) {
            return notifications.show(body.error, 'error');
          }
          return notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
        });
    },
  });

  const subscOnStandardPlan = subscription?.data?.subscription?.countryPlan?.plan?.titleEn
    ?.toLowerCase()
    .includes('standard');

  const zydaMail = user.email.split('@')[1] === 'zyda.com';

  return (
    <Query
      query={schemas.LIST}
      fetchPolicy="cache-and-network"
      variables={{ restaurantId: selectedStoreId.toString() }}
    >
      {({ loading, data }) => (
        <Layout
          top={
            <Breadcrumbs
              helperLinks={BUSINESS_LOCATION_LINKS}
              path={breadcrumbs.SETUP_BUSINESS_LOCATIONS}
              right={
                data?.branches && (
                  <>
                    <BranchAddingModal
                      translate={translate}
                      numberOfBranches={data.branches.length}
                      planLimit={selectedStore.subscriptionPlanType === PLAN_TYPES.GROWTH ? 10 : 1}
                      direction={direction}
                    />
                  </>
                )
              }
            />
          }
        >
          <div style={{ direction }}>
            {loading ? (
              <Spinner />
            ) : !data.branches ? (
              <EmptyState subscOnStandardPlan={subscOnStandardPlan} zydaMail={zydaMail} />
            ) : (
              <Table storeId={selectedStoreId} items={data.branches} />
            )}
          </div>
        </Layout>
      )}
    </Query>
  );
};
