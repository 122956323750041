import request from 'axios';

import { axios, createFormData, cleanObject } from './utils';
import * as userService from 'services/user';

const { REACT_APP_API_GATEWAY_URL } = process.env;

export const uploadStoreImages = (storeId, { logo, header }, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        logo_url: logo,
        photo_url: header,
      }),
    ),
    headers: {
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};

export const uploadLegalImages = (storeId, { front, back, license, authorizedSignatory }, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/business_legal_datum/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        national_front_copy: front,
        national_back_copy: back,
        license_copy: license,
        authorized_signatory_copy: authorizedSignatory,
      }),
    ),
    headers: {
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};

export const uploadCategoryImage = (storeId, menuSectionId, image, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/menu_sections/${menuSectionId}/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        photo_url: image,
      }),
    ),
    cancelToken,
    headers: {
      APPVERSION: 2,
      authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCategoryImage = (storeId, menuSectionId, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/menu_sections/${menuSectionId}/delete_photo`,
    method: 'DELETE',
    cancelToken,
    headers: {
      APPVERSION: 2,
      authorization: `Bearer ${token}`,
    },
  });
};

export const removeNationalFront = storeId => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/business_legal_datum/delete_national_front_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removeNationalBack = storeId => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/business_legal_datum/delete_national_back_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removeLicense = storeId => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/business_legal_datum/delete_license_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removeAuthorizedSignatory = storeId => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/business_legal_datum/delete_authorized_signatory_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const uploadVariantImages = (storeId, variantId, photoUrl, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/variants/${variantId}/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        photo_url: photoUrl,
      }),
    ),
    cancelToken,
    headers: {
      APPVERSION: 2,
      authorization: `Bearer ${token}`,
    },
  });
};

export const removeVariantImage = (storeId, variantId, photoId) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/variants/${variantId}/delete_photo/${photoId}`,
    method: 'DELETE',
    headers: {
      APPVERSION: 2,
      authorization: `Bearer ${token}`,
    },
  });
};

export const getFoodicsBranches = storeId => {
  return request({
    url: `/v1/pos/store/${storeId}/menus/foodics/branches`,
    method: 'GET',
    baseURL: REACT_APP_API_GATEWAY_URL,
  });
};

export const importCatalog = (storeId, fileCatalog, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/import_menu_from_file_async`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        file: fileCatalog,
      }),
    ),
    headers: {
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};

export const exportCatalog = storeId => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/export_menu`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportInvoices = storeId => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_invoices?access_token=${token}`;
};

export const downloadCatalog = () => {
  return `https://zyda-import-sheets.s3-eu-west-1.amazonaws.com/zyda_import_catalog_template.xlsx`;
};

export const importStock = (storeId, file) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/import_inventory_stocks_from_file`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        file,
      }),
    ),
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportStockUrl = (storeId, template = false) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_inventory_stocks?access_token=${token}${
    template ? '&template=true' : ''
  }`;
};

export const exportOrdersReport = (storeId, filters) => {
  const {
    customerName,
    number,
    sort,
    submittedAt,
    phone,
    status,
    paymentStatuses,
    paymentMethod,
    deliveryType,
    areas,
    branchId,
    statuses,
    voucherCode,
    products_included,
    all,
    isManualOrder,
  } = filters;
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/reports/orders`,
    method: 'POST',
    params: {
      'filter[branch_id_in]': !!branchId ? branchId : undefined,
      'filter[payment_status_in]': paymentStatuses,
      'filter[submitted_at_gteq]': submittedAt && submittedAt[0],
      'filter[submitted_at_lteq]': submittedAt && submittedAt[1],
      'filter[phone_number_cont]': !!phone ? phone : undefined,
      'filter[name_cont]': customerName,
      'filter[paid_through_in]': paymentMethod,
      'filter[number_eq]': number,
      'filter[status_eq]': status,
      'filter[status_in]': statuses,
      'filter[delivery_type_in]': deliveryType,
      'filter[area_in]': areas,
      'filter[voucher_code_eq]': voucherCode,
      'filter[is_manual_order_eq]': isManualOrder,
      all,
      products_included,
      sort,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportReport = (storeId, startDate, endDate, type) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_sales_breakdown?start_date=${startDate}&end_date=${endDate}&date=${type}&access_token=${token}`;
};
export const exportProductsReport = (storeId, startDate, endDate) => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/export_top_selling_products`,
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportNewVsReturningReport = (storeId, startDate, endDate, date, metric) => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/export_new_vs_returning`,
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
      date: date,
      metric: metric,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportCategoriesReport = (storeId, startDate, endDate, type) => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/export_top_categories`,
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const exportBehaviorReport = (storeId, startDate, endDate, date, breakdown, metric) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_behavior_breakdown?start_date=${startDate}&end_date=${endDate}&date=${date}&breakdown=${breakdown}&metric=${metric}&access_token=${token}`;
};

export const exportGeoBranchesReport = (storeId, startDate, endDate) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_geo_heatmap_branches?start_date=${startDate}&end_date=${endDate}&access_token=${token}`;
};

export const exportGeoAreasReport = (storeId, startDate, endDate, branchId) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_geo_heatmap_areas?start_date=${startDate}&end_date=${endDate}&branch_id=${branchId}&access_token=${token}`;
};

export const exportHistoryLog = (storeId, categoryId, title, branchId, startDate, endDate) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = userService.getToken();

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/export_stocks_history_log?filter[variant_menu_item_menu_sections_uuid_eq]=${
    categoryId === 'all' ? '' : categoryId
  }&filter[variant_menu_item_title_ar_or_variant_menu_item_title_en_or_variant_title_en_or_variant_title_ar_or_variant_bar_code_or_variant_sku_cont]=${
    title ? title : ''
  }&filter[branch_id_eq]=${branchId}&filter[created_at_lteq]=${endDate ? endDate : ''}&filter[created_at_gteq]=${
    startDate ? startDate : ''
  }&sort=-created_at&access_token=${token}`;
};

export const uploadPaymentImages = (storeId, id, { front, back, license, authorizedSignatory }, cancelToken) => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/payment_credentials/${id}/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        id_front_copy: front,
        id_back_copy: back,
        license_copy: license,
        authorized_signatory_copy: authorizedSignatory,
      }),
    ),
    headers: {
      APPVERSION: 2,
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};
export const removePaymentNationalFront = (storeId, id) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/payment_credentials/${id}/delete_id_front_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removePaymentNationalBack = (storeId, id) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/payment_credentials/${id}/delete_id_back_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removePaymentLicense = (storeId, id) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/payment_credentials/${id}/delete_license_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const removePaymentAuthorizedSignatory = (storeId, id) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/payment_credentials/${id}/delete_id_authorized_signatory_copy`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const uploadProductImage = (storeId, id, { productImage }, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/menu_items/${id}/image_upload`,
    method: 'POST',
    data: createFormData(
      cleanObject({
        photo_url: productImage,
      }),
    ),
    headers: {
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};

export const deleteProductImage = (storeId, id, cancelToken) => {
  const token = userService.getToken();

  return axios({
    url: `/restaurants/${storeId}/menu_items/${id}/delete_photo`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
    },
    cancelToken,
  });
};

export const purchasePackage = (storeId, id) => {
  const { REACT_APP_BACKEND_URL } = process.env;

  return `${REACT_APP_BACKEND_URL}/restaurants/${storeId}/invoices/sms_packages/${id}`;
};

export const exportCustomersTable = storeId => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/export_all_customers`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const enableVerd = (restaurant, branchesIds, enableVerd) => {
  const token = userService.getToken();
  return request({
    url: '/v1/delivery/verd/integrate',
    method: 'POST',
    baseURL: REACT_APP_API_GATEWAY_URL,
    data: {
      restaurant: {
        id: restaurant.id,
        name: restaurant.titleEn,
        subdomain: restaurant.subdomain,
      },
      branch_ids: branchesIds,
      enable_verd: enableVerd,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const fetchDeliveryZonesMsToken = storeId => {
  const token = userService.getToken();
  return axios({
    url: `/restaurants/${storeId}/delivery_zones_ms_token`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
