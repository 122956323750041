import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InfoIcon } from '@zydalabs/zac-icons-react';
import { Button, Tag } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { Error } from 'components/form/generic';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { GET_WITH_GROWTH, UPGRADE_YOUR_FREE_PLAN } from 'constants/translations';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { CAMPAIGN_TYPES } from './constants';

const sizes = {
  xs: 'py-1 px-2 text-base',
  sm: 'py-1 px-3 text-base',
  base: 'p-6 text-base',
};

const colors = {
  'primary-base': '#234cfb',
  'gray-600': '#718096',
};

const CampaignTypeRadioGroup = ({ items, onChange, value, name, size = 'base', error = '', remainingCampaign }) => {
  const { lang, direction, translate } = useContext(localeContext);
  const itemsLength = items.length;
  const handleOnClick = targetValue => {
    onChange({
      target: {
        name,
        value: targetValue,
      },
    });
  };
  return (
    <div>
      <div className={`grid grid-rows-${itemsLength} md:grid-cols-${itemsLength} md:gap-4 pb-32`}>
        {items.map((item, index) => {
          const isSelected = item.value === value;
          const { disable } = item;
          return (
            <div
              role="radiogroup"
              tabIndex={index}
              key={item.value}
              className={cx(
                'flex flex-row mt-4 items-start border',
                item.upgradeable ? 'cursor-default' : 'hover:border-primary-base cursor-pointer',
                sizes[size],
                lang === 'en' ? '' : '',
                isSelected ? 'border-primary-base bg-primary-50' : 'border-gray-300',
              )}
              onKeyDown={() => {
                !item.upgradeable && handleOnClick(item.value);
              }}
              onClick={() => {
                !item.upgradeable && handleOnClick(item.value);
              }}
            >
              {item.icon && (
                <div className={cx('flex flex-col justify-start h-full', lang === 'en' ? 'pr-2' : 'pl-2')}>
                  {item.icon(isSelected ? colors['primary-base'] : colors['gray-600'])}
                </div>
              )}
              <div className="flex flex-col justify-start h-full">
                <Text
                  className={cx('font-semibold pb-1 flex', isSelected ? 'text-primary-base' : 'text-gray-600')}
                  postfixElement={
                    item.upgradeable && (
                      <div className="mx-1">
                        <Tag
                          color="orange"
                          inner={{
                            text: translate(GET_WITH_GROWTH),
                            icon: <UpgradeRocketIcon />,
                          }}
                          size="medium"
                        />
                      </div>
                    )
                  }
                  value={item.title}
                />
                <Text className="font-normal text-sm text-gray-600" value={item.description} />
                {item.value === CAMPAIGN_TYPES.ANNOUNCEMENT && !item.upgradeable && remainingCampaign && (
                  <div
                    className={cx(
                      'mt-4 p-2 border flex items-center justify-between flex-wrap',
                      disable ? 'border-red-500 bg-red-100' : 'border-gray-200 bg-gray-100',
                    )}
                    style={{ direction }}
                  >
                    <div className="flex items-start">
                      <InfoIcon
                        width="16"
                        color={disable ? '#AA3737' : '#1F1F1F'}
                        className={cx('flex-shrink-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
                      />
                      <Text
                        className="text-xs"
                        value={disable ? translations.MAXIMUM_CAMPAIGN_LIMIT : translations.REMAINING_CAMPAIGN_NOTE}
                        payload={remainingCampaign}
                      />
                    </div>
                  </div>
                )}
                {item.upgradeable && (
                  <div className="w-40 mt-4">
                    <Button // Do Nothing for now
                      type="button"
                      size="small"
                      rounded
                      text={translate(UPGRADE_YOUR_FREE_PLAN)}
                      onClick={() => {
                        if (window.Intercom) window.Intercom('show');
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  );
};

CampaignTypeRadioGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'base']),
  error: PropTypes.string,
  remainingCampaign: PropTypes.number,
};

export default CampaignTypeRadioGroup;
