import React, { useContext } from 'react';
import { Button, Tag, Tooltip } from '@zydalabs/zac-react';
import { useMutation } from '@apollo/client';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import { initialSegments } from 'constants/customers';
import { CAMPAIGN_TYPES } from 'pages/marketing/campaigns/Create/constants';
import { VOUCHER_TYPE } from 'pages/marketing/constants';
import * as schemas from '../../schemas';

const Block = ({ label, value, span = 1, tooltipText = '' }) => (
  <div className={`inline-flex flex-col col-span-${span} mb-1 mt-1`}>
    <div className="inline-flex flex-row">
      <div className="mt-2 mb-1 text-sm text-gray-600">{`${label}`}</div>
      {tooltipText && (
        <div className="mt-3 ml-2">
          <Tooltip info={tooltipText} isDisabled={false} size="small" placement="bottom" key={label} />
        </div>
      )}
    </div>
    <span className="font-semibold">{value}</span>
  </div>
);

const Divider = () => <div className="mt-4 mb-4 h-1 flex-col col-span-2 border-b" />;

export default ({ item, storeId, setIsSlideOverOpen }) => {
  const { translate, direction, lang } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);

  const orderCurrency = lang === 'en' ? selectedStore.currency.titleEn : selectedStore.currency.titleAr;

  const type = {
    ConversionCampaign: translate(translations.CONVERSION),
    RetentionCampaign: translate(translations.RETENTION),
    RetargetingCampaign: translate(translations.SEGMENT_TARGETING),
  };

  const statuses = {
    activated: 'activated',
    scheduled: 'scheduled',
    deactivated: 'deactivated',
    completed: 'completed',
    draft: 'draft',
  };

  const status = {
    activated: translate(translations.ACTIVE),
    scheduled: translate(translations.SCHEDULED),
    deactivated: translate(translations.DEACTIVATED),
    completed: translate(translations.COMPLETED_CAMPAIGN),
    draft: translate(translations.DRAFT_CAMPAIGN),
  };

  const campaignStateColor = () => {
    switch (status[item.state]) {
      case status.activated:
        return 'success';
      case status.completed:
        return 'success';
      case status.scheduled:
        return 'secondary';
      case status.deactivated:
        return 'danger';
      case status.draft:
        return 'positive';
      default:
        return '';
    }
  };

  const campaignActionButtonVariant = () => {
    ``;
    switch (status[item.state]) {
      case status.activated:
        return 'danger';
      case status.draft:
        return 'primary';
      case status.deactivated:
        return 'primary';
      default:
        return '';
    }
  };

  const [updateCampaign] = useMutation(schemas.UPDATE_CAMPAIGN, {
    variables: {
      storeId: storeId,
      campaignId: item.id,
    },
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body;

      for (let key of Object.keys(body)) {
        notifications.show(`${body[key][0]}`, 'error');
      }
    },
    onCompleted: () => {
      notifications.show(translations.CAMPAIGN_UPDATED);
      setIsSlideOverOpen(false);
    },
  });

  /**
   *
   * @param {number} totalSalesFromCampaignVouchers
   * @param {number} totalSales
   * @param {string} currency
   * @returns
   */
  const getPerformanceOrdersGenerateValue = (totalSalesFromCampaignVouchers, totalSales, currency) => {
    if (!totalSalesFromCampaignVouchers) {
      if (totalSales) return `${totalSales} ${currency}`;
      return `${0} ${currency}`;
    }
    return `${totalSalesFromCampaignVouchers} ${currency}`;
  };

  return (
    <div className="px-4 overflow-x-auto overflow-y-auto">
      <div className="grid grid-cols-2 mb-4 mt-8 mx-4 positive text-sm whitespace-normal " dir={direction}>
        <div className="header inline-flex flex-row justify-between col-span-2">
          <p className="col-span-1 text-2xl font-semibold max-w-xs">{item.title}</p>
          <div className="max-h-sm ml-2">
            <Tag
              className="col-span-1 max-h-2"
              size="large"
              inner={{ text: status[item.state] }}
              color={campaignStateColor()}
            />
          </div>
        </div>
        {(item.state === statuses.activated ||
          item.state === statuses.deactivated ||
          item.state === statuses.draft) && (
          <div className="header col-span-2 text-2xl mt-4">
            <Button
              text={
                status[item.state] === status.activated
                  ? translate(translations.DEACTIVATE)
                  : translate(translations.ACTIVATE)
              }
              rounded={true}
              variant={campaignActionButtonVariant()}
              onClick={() =>
                updateCampaign({
                  variables: {
                    state: item.state === statuses.activated ? statuses.deactivated : statuses.deactivated,
                  },
                })
              }
              AutoLayout
            />
          </div>
        )}

        <Divider />

        <Block span={2} label={translate(translations.TYPE)} value={type[item.type]} />
        {item.type === CAMPAIGN_TYPES.SEGMENT_TARGETING && (
          <Block
            span={2}
            label={translate(translations.TARGETED_SEGMENT)}
            value={translate([
              initialSegments[item.crmSegmentId || 0].titleEn,
              initialSegments[item.crmSegmentId || 0].titleAr,
            ])}
          />
        )}
        <Block
          label={translate(translations.SENT_SMS)}
          value={`${item.sentSmsCount}/${item.smsSendingLimit || translate(translations.UNLIMITED)}`}
        />
        <Block
          label={translate(translations.CREDITS_CONSUMED)}
          value={`${item.sentSmsCount * (item.estimatedCreditPerSms || 1)} ${translate(translations.CREDIT)}`}
        />
        {item.voucherType === VOUCHER_TYPE.AMOUNT && (
          <Block
            label={translate(translations.VOUCHER_TYPE)}
            value={`${item.voucherAmount} ${selectedStore.currency.titleEn}`}
          />
        )}
        {item.voucherType === VOUCHER_TYPE.FREE_DELIVERY && (
          <Block label={translate(translations.VOUCHER_TYPE)} value={translate(translations.FREE_DELIVERY)} />
        )}
        {item.voucherType === VOUCHER_TYPE.PERCENTAGE && (
          <Block
            label={translate(translations.VOUCHER_TYPE)}
            value={`${item.voucherAmount}${translate(translations.PERCENTAGE_OFF)}`}
          />
        )}
        {item.voucherType === VOUCHER_TYPE.NO_VOUCHER && (
          <Block label={translate(translations.VOUCHER_TYPE)} value={`${translate(translations.NO_VOUCHER)}`} />
        )}

        <Block
          label={translate(translations.REDEMPTION_LIMIT_PER_USER)}
          value={
            (item.voucherRedemptionLimitPerUser === 0 && translate(translations.UNLIMITED)) ||
            (item.voucherRedemptionLimitPerUser === 1 &&
              `${item.voucherRedemptionLimitPerUser} ${translate(translations.USE)}`) ||
            (item.voucherRedemptionLimitPerUser !== 1 &&
              `${item.voucherRedemptionLimitPerUser} ${translate(translations.USES)}`)
          }
        />
        <Block label={translate(translations.START_ON)} value={item.startsAt} />
        <Block label={translate(translations.END_ON)} value={item.expiresAt} />
        <Block span={2} label={translate(translations.SAMPLE_SMS_BODY)} value={item.generateSmsBody} />

        <Divider />

        <div className="header inline-flex flex-row justify-between col-span-2">
          <p className="col-span-1 text-xl font-semibold">{translate(translations.VOUCHER)}</p>
        </div>

        <Block
          label={translate(translations.NUMBER_VOUCHER_REDEMPTION)}
          value={
            item.voucherRedemptionsCount === 1
              ? `${item.voucherRedemptionsCount} ${translate(translations.ORDER)}`
              : `${item.voucherRedemptionsCount} ${translate(translations.ORDERS)}`
          }
          tooltipText={translate(translations.NUMBER_VOUCHER_REDEMPTION_TOOLTIP)}
        />
        <Block
          label={translate(translations.SALES_OF_VOUCHER_REDEMPTION)}
          value={getPerformanceOrdersGenerateValue(
            item.stats?.totalSalesFromCampaignVouchers,
            item.stats?.totalSales,
            orderCurrency,
          )}
          tooltipText={translate(translations.SALES_OF_VOUCHER_REDEMPTION_TOOLTIP)}
        />

        <Divider />

        <div className="header inline-flex flex-row justify-between col-span-2">
          <p className="col-span-1 text-xl font-semibold">{translate(translations.PERFORMANCE)}</p>
        </div>

        <Block
          label={translate(translations.ORDERS_GENERATED)}
          value={`${item.stats?.campaignOrders}` || 'N/A'}
          tooltipText={translate(translations.ORDERS_GENERATED_TOOLTIP)}
        />
        <Block
          label={translate(translations.SALES_GENERATED)}
          value={`${item.stats?.campaignSales} ${orderCurrency}` || 'N/A'}
          tooltipText={translate(translations.SALES_GENERATED_TOOLTIP)}
        />
      </div>
    </div>
  );
};
