import React from 'react';
import { Button, ProgressIndicator, Tag } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Modal } from 'components/kit';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';

const BranchAddingModal = ({ numberOfBranches = 0, planLimit = 1, translate, direction }) => (
  <Modal customWidth="max-w-xl">
    {({ open, close }) => (
      <>
        <Button
          onClick={() =>
            open({
              size: 'w-full',
              title: (
                <div>
                  <Text className="font-semibold text-2xl" value={translations.ADD_A_NEW_BRANCH} />
                </div>
              ),
              body: (
                <div className="mx-auto w-full">
                  <div className="flex flex-col px-8">
                    <div className="my-4 mx-0">
                      <Text
                        className="text-lg font-bold text-black mb-2 mt-0 mx-0"
                        value={translations.GLAD_YOU_EXPAND}
                      />
                      <Text
                        className="text-black text-base font-normal"
                        value={translations.SUPPORT_TEAM_IS_READY_TO_HELP}
                      />
                    </div>
                    <div className={cx('mb-12 flex', direction === 'rtl' ? 'flex-row-reverse' : 'flex-row')}>
                      <ProgressIndicator
                        progress={numberOfBranches > planLimit ? 100 : (numberOfBranches / planLimit) * 100}
                        radiusSize="small"
                        type="circular"
                      />
                      <div className="p-2">
                        <div className={cx('flex', direction === 'rtl' ? 'flex-row-reverse' : 'flex-row')}>
                          <Text
                            className={cx('font-semibold', direction === 'ltr' ? 'w-36' : 'w-40')}
                            value={translations.NUMBER_OF_BUSINESS_LOCATIONS(numberOfBranches, planLimit)}
                          />
                          {planLimit === 1 && (
                            <div className={cx('flex', direction === 'ltr' ? 'w-72' : 'w-52 mr-2 justify-end')}>
                              <Tag
                                color="orange"
                                inner={{
                                  text: translate(translations.GET_WITH_GROWTH),
                                  icon: <UpgradeRocketIcon />,
                                }}
                                size="small"
                              />
                            </div>
                          )}
                        </div>
                        <Text
                          className="opacity-60"
                          value={
                            numberOfBranches >= planLimit
                              ? translations.YOU_HAVE_REACHED_YOUR_PLANS_LIMIT
                              : translations.YOU_CAN_ADD_A_LOCATION
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={cx(
                        'flex space-x-3 mb-4',
                        direction === 'rtl' ? 'flex-row-reverse space-x-reverse' : 'flex-row',
                      )}
                    >
                      <Button
                        onClick={() => window.Intercom('show')}
                        type="button"
                        size="large"
                        isFluid
                        rounded
                        text={translate(translations.REQUEST_NEW_BRANCH)}
                      />
                      <Button
                        onClick={() => close()}
                        className="text-base text-primary-base"
                        variant="ghost"
                        text={translate(translations.CANCEL)}
                      />
                    </div>
                  </div>
                </div>
              ),
            })
          }
          type="button"
          size="medium"
          startIcon={<UpgradeRocketIcon className="mr-3" />}
          rounded
          text={translate(translations.ADD_BUSINESS_LOCATION)}
        />
      </>
    )}
  </Modal>
);

BranchAddingModal.PropType = {
  numberOfBranches: PropTypes.number,
  planLimit: PropTypes.number,
  translate: PropTypes.func,
};

export default BranchAddingModal;
